import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettingsService from '@/services/UserSettings'
import BalanceHistoryService from '@/services/BalanceHistory'
import UserPaymentsService from '@/services/UserPayments'
import moment from 'moment'

@Component({})
export default class ManageAgent extends BasePage {
  constructor() {
    super()
  }

  // Table
  tableHeaders: object[] = [
    {
      text: 'ID',
      align: 'center',
      sortable: true,
      value: 'id'
    },
    {
      text: 'Date',
      align: 'center',
      sortable: false,
      value: 'date'
    },
    {
      text: 'Type',
      align: 'center',
      sortable: false,
      value: 'type'
    },
    {
      text: 'Ref',
      align: 'center',
      sortable: false,
      value: 'ref'
    },
    {
      text: 'Amount',
      align: 'right',
      sortable: false,
      value: 'amount'
    },
    {
      text: 'Balance',
      align: 'right',
      sortable: false,
      value: 'balance'
    }
  ]
  tableItems: object[] = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: '-created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = false

  // id current user
  idUser: string | number = null

  // data user
  userDetails: any = {}

  // add balance history
  balanceDialog: boolean = false
  balanceDialogData: any = {
    amount: null,
    commission_type: {
      items: [
        {
          text: 'Increase',
          value: 1
        },
        {
          text: 'Decrease',
          value: 2
        }
      ],
      selected: null
    },
    label: {
      items: [],
      selected: null
    }
  }

  // withdraw settings
  withdrawDialog: boolean = false
  withdrawDialogData: any = {
    method: {
      items: [],
      selected: null,
      loading: false
    },
    bank_name: null,
    bank_account_name: null,
    bank_account_number: null
  }
  withdrawDetails: any = {}
  isEdit: boolean = true

  mounted() {
    this.idUser = this.$route.params.id
    this.getUsers()
  }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getBalance()
  }

  onChangeCommissionType() {
    if (this.balanceDialogData.commission_type.selected === 1) {
      this.balanceDialogData.label.items = ['Bonus', 'Order Commission', 'Balance Adjustment']
    } else if (this.balanceDialogData.commission_type.selected === 2) {
      this.balanceDialogData.label.items = ['Withdrawal', 'Balance Adjustment']
    }
  }

  // get current user
  async getUsers() {
    this.showLoading({ text: 'Loading...' })
    const opts: any = {
      params: {
        include: 'payments,codes'
      }
    }

    try {
      const response = await UserSettingsService.getOneUser(this.idUser, opts)
      const currentUser = response.data.data

      this.userDetails = currentUser.attributes

      if (this.userDetails.country_signup) {
        this.userDetails.currency = response.data.included.signupcountry[this.userDetails.country_signup].attributes.currency
      } else {
        this.userDetails.currency = 'IDR'
      }

      if (!this.userDetails.balance) {
        this.userDetails.balance = 0
      }

      if (!isEmpty(currentUser.relationships.payments)) {
        this.isEdit = true
        const idPayments = currentUser.relationships.payments[0].id
        const userPayment = await this.getUserPayment(idPayments)
        this.withdrawDetails = userPayment
      } else {
        this.isEdit = false
        this.withdrawDetails = {
          remarks: null,
          value: null,
          id_payment: null
        }
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // get user payment
  async getUserPayment(id) {
    const opts: any = {
      params: {
        include: 'user,payment'
      }
    }

    try {
      const response = await UserPaymentsService.getOneUserPayments(id, opts)
      const responseData = response.data.data
      const userPayment = responseData.attributes
      userPayment.id_payment = responseData.relationships.payment.id
      return userPayment
    } catch (error) {
      this.catchHandler(error)
    } finally {
    }
  }

  // get balance history
  async getBalance() {
    this.tableLoading = true
    const opts: any = {
      params: {
        'page[num]': this.tablePagination.page,
        'page[limit]': this.tablePagination.rowsPerPage,
        'filter[user.id][is]': this.idUser,
        sort: '-created_at'
      }
    }

    try {
      const response = await BalanceHistoryService.getBalanceHistory(opts)
      const responseData = response.data.data
      const responseMeta = response.data.meta

      this.tableItems = []
      forEach(responseData, dataBalance => {
        const balance = dataBalance.attributes
        balance.date = moment(balance.created_at).format('MMM, DD YYYY')
        balance.order_ref = balance.order_ref ? '#' + balance.order_ref : '-'
        this.tableItems.push(balance)
      })

      this.tableTotalItems = responseMeta.pagination.total
      this.tableLoading = false

    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  // open add balance history dialog
  openAddBalanceDialog() {
    this.balanceDialogData.amount = null
    this.balanceDialogData.commission_type.selected = null
    this.balanceDialogData.label.selected = null
    this.balanceDialog = true
  }

  // add new balance history
  async addBalanceHistory() {
    try {
      const validationResponse = await this.$validator.validateAll('balanceDialogScope')
      if (validationResponse) {
        this.balanceDialog = false
        this.showLoading({ text: 'Creating...' })
        const payload = {
          user_id: this.idUser,
          commission_type: this.balanceDialogData.commission_type.selected,
          label: this.balanceDialogData.label.selected,
          currency: this.userDetails.currency,
          amount: this.balanceDialogData.amount
        }

        BalanceHistoryService.setBalanceHistory(payload).then(response => {
          this.showSnackbar({
            text: 'Saved Successfully!',
            color: 'green',
            timeout: 1500
          })
          this.getBalance()
          this.getUsers()
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  // open withdraw setting dialog
  openWithdrawDialog() {
    this.withdrawDialog = true
    this.withdrawDialogData.method.selected = Number(this.withdrawDetails.id_payment)
    this.withdrawDialogData.bank_account_name = this.withdrawDetails.remarks
    this.withdrawDialogData.bank_account_number = this.withdrawDetails.value
    this.getPaymentList()
  }

  async getPaymentList() {
    try {
      this.withdrawDialogData.method.loading = true
      const response = await UserPaymentsService.getPaymentPlatforms()
      const responseData = response.data.data
      forEach(responseData, dataPayment => {
        if (dataPayment.attributes.available) {
          this.withdrawDialogData.method.items.push(dataPayment.attributes)
        }
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.withdrawDialogData.method.loading = false
    }
  }

  async saveWithdrawSetting() {
    try {
      const validation = await this.$validator.validateAll('withdrawDialogScope')
      if (validation) {
        this.withdrawDialog = false
        this.showLoading({ text: 'Saving...' })
        if (this.isEdit) {
          ;
          if (this.userDetails.country_catalogs === null) {
            this.userDetails.country_catalogs = []
          }
          const payload = {
            ...this.userDetails,
            relationships: [
              {
                type: 'user_payments',
                action: 'update',
                id: this.withdrawDetails.id,
                attributes: {
                  value: this.withdrawDialogData.bank_account_number,
                  remarks: this.withdrawDialogData.bank_account_name,
                  payment_id: this.withdrawDialogData.method.selected
                }
              }
            ]
          }
          UserSettingsService.update(this.userDetails.id, payload).then(response => {
            this.showSnackbar({
              text: 'Saved Successfully!',
              color: 'green',
              timeout: 1500
            })
            this.getUsers()
          }).catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: 'red',
              timeout: 2000
            })
          })
        } else {
          const payload = {
            value: this.withdrawDialogData.bank_account_number,
            user_id: this.userDetails.id,
            payment_id: this.withdrawDialogData.method.selected,
            remarks: this.withdrawDialogData.bank_account_name
          }
          UserPaymentsService.setUserPayments(payload).then(response => {
            this.showSnackbar({
              text: 'Saved Successfully!',
              color: 'green',
              timeout: 1500
            })
            this.getUsers()
          }).catch(error => {
            this.showSnackbar({
              text: error.response.data.message,
              color: 'red',
              timeout: 2000
            })
          })
        }
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }
}
