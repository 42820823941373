import { render, staticRenderFns } from "./balanceHistory.pug?vue&type=template&id=46d7e6c8&scoped=true&lang=pug&"
import script from "./balanceHistory.ts?vue&type=script&lang=ts&"
export * from "./balanceHistory.ts?vue&type=script&lang=ts&"
import style0 from "./balanceHistory.scss?vue&type=style&index=0&id=46d7e6c8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d7e6c8",
  null
  
)

export default component.exports